const productionDomains = [
	'engros.ae', 'engros.app', 'engros.at', 'engros.dev', 'engros.es',
	'engros.fi', 'engros.in', 'engros.io', 'engros.it', 'engros.nz',
	'engros.pt', 'engros.se', 'engros.uk', 'partihandel.io',
	'partihandel.se', 'partihanteraren.com','pifa.se'
];

const getEnvironment = () => {
	const host = window.location.host;
	if (productionDomains.some(domain => host.includes(domain))) {
		return 'production';
	} else if (host.includes('pifa.dev')) {
		return 'sandbox';
	} else {
		return 'development';
	}
};

const environment = getEnvironment();

const getApiBaseUrl = () => {
	switch (environment) {
		case 'production':
			return `https://api.${window.location.host}`;
		case 'sandbox':
			return 'https://api.pifa.dev';
		default:
			return 'http://localhost:8080';
	}
};

const getAppUrl = () => {
	switch (environment) {
		case 'production':
			return `https://app.${window.location.host}`;
		case 'sandbox':
			return 'https://app.pifa.dev';
		default:
			return 'http://localhost:3000';
	}
};

const getLoginUrl = () => {
	switch (environment) {
		case 'production':
			return `https://id.${window.location.host}`;
		case 'sandbox':
			return 'https://id.pifa.dev';
		default:
			return 'http://localhost:2000';
	}
};

// You can also export the environment for use in other parts of your application
export const isProduction = environment === 'production';
export const isSandbox = environment === 'sandbox';
export const isDevelopment = environment === 'development';

export { getApiBaseUrl, getAppUrl, getLoginUrl };