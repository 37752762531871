import React from 'react';
import i18next from 'i18next';
import {IconButton, Menu, MenuItem, MenuList, Tooltip, Typography} from '@mui/material';
import {Language} from '@mui/icons-material';

export const LanguageSelector = ({color}) => {
	const [anchorElSupport, setAnchorElSupport] = React.useState(null);

	const languages = [
		{
			locale: 'ar-AE',
			attributeName: 'arabic',
			localName: 'العربية',
			englishName: 'Arabic',
		},
		{
			locale: 'fr-FR',
			attributeName: 'french',
			localName: 'Français',
			englishName: 'French',
		},
		{
			locale: 'da-DK',
			attributeName: 'danish',
			localName: 'Dansk',
			englishName: 'Danish',
		},
		{
			locale: 'de-DE',
			attributeName: 'german',
			localName: 'Deutsch',
			englishName: 'German',
		},
		{
			locale: 'en-US',
			attributeName: 'english',
			localName: 'English',
			englishName: 'English',
		},
		{
			locale: 'es-ES',
			attributeName: 'spanish',
			localName: 'Español',
			englishName: 'Spanish',
		},

		{
			locale: 'hu-HU',
			attributeName: 'hungarian',
			localName: 'Magyar',
			englishName: 'Hungarian',
		},
		{
			locale: 'it-IT',
			attributeName: 'italian',
			localName: 'Italiano',
			englishName: 'Italian',
		},

		{
			locale: 'nl-NL',
			attributeName: 'dutch',
			localName: 'Nederlands',
			englishName: 'Dutch',
		},
		{
			locale: 'no-NO',
			attributeName: 'norwegian',
			localName: 'Norsk',
			englishName: 'Norwegian',
		},
		{
			locale: 'ru-RU',
			attributeName: 'russian',
			localName: 'Русский',
			englishName: 'Russian',
		},
		{
			locale: 'fi-FI',
			attributeName: 'finnish',
			localName: 'Suomi',
			englishName: 'Finnish',
		},
		{
			locale: 'sv-SE',
			attributeName: 'swedish',
			localName: 'Svenska',
			englishName: 'Swedish',
		},
		{
			locale: 'tr-TR',
			attributeName: 'turkish',
			localName: 'Türkçe',
			englishName: 'Turkish',
		},
		{
			locale: 'zh-CN',
			attributeName: 'chinese',
			localName: '中文',
			englishName: 'Chinese',
		} /*
					{
			locale: 'sq-AL',
			attributeName: 'albanian',
			localName: 'Shqip',
			englishName: 'Albanian',
		},
		{
			locale: 'hy-AM',
			attributeName: 'armenian',
			localName: 'Հայերեն',
			englishName: 'Armenian',
		},
			{
			locale: 'mk-MK',
			attributeName: 'macedonian',
			localName: 'Македонски',
			englishName: 'Macedonian',
		},
		{
			locale: 'az-AZ',
			attributeName: 'azerbaijani',
			localName: 'Azərbaycan dili',
			englishName: 'Azerbaijani',
		},
		{
			locale: 'be-BY',
			attributeName: 'belorussian',
			localName: 'Беларуская',
			englishName: 'Belorussian',
		},
		{
			locale: 'bs-BA',
			attributeName: 'bosnian',
			localName: 'Bosanski',
			englishName: 'Bosnian',
		},
		{
			locale: 'bg-BG',
			attributeName: 'bulgarian',
			localName: 'Български',
			englishName: 'Bulgarian',
		},
		{
			locale: 'ca-ES',
			attributeName: 'catalan',
			localName: 'Català',
			englishName: 'Catalan',
		},
		{
			locale: 'hr-HR',
			attributeName: 'croatian',
			localName: 'Hrvatski',
			englishName: 'Croatian',
		},
		{
			locale: 'cs-CZ',
			attributeName: 'czech',
			localName: 'Čeština',
			englishName: 'Czech',
		},

		{
			locale: 'nl-BE',
			attributeName: 'flamish',
			localName: 'Vlaams',
			englishName: 'Flemish',
		},
		{
			locale: 'et-EE',
			attributeName: 'estonian',
			localName: 'Eesti',
			englishName: 'Estonian',
		},

		{
			locale: 'ka-GE',
			attributeName: 'georgian',
			localName: 'ქართული',
			englishName: 'Georgian',
		},
		{
			locale: 'el-GR',
			attributeName: 'greek',
			localName: 'Ελληνικά',
			englishName: 'Greek',
		},
		{
			locale: 'he-IL',
			attributeName: 'hebrew',
			localName: 'עברית',
			englishName: 'Hebrew',
		},
		{
			locale: 'hi-IN',
			attributeName: 'hindu',
			localName: 'हिन्दी',
			englishName: 'Hindu',
		},
		{
			locale: 'is-IS',
			attributeName: 'icelandic',
			localName: 'Íslenska',
			englishName: 'Icelandic',
		},
		{
			locale: 'id-ID',
			attributeName: 'indonesian',
			localName: 'Bahasa Indonesia',
			englishName: 'Indonesian',
		},
		{
			locale: 'ja-JP',
			attributeName: 'japanese',
			localName: '日本語',
			englishName: 'Japanese',
		},
		{
			locale: 'kk-KZ',
			attributeName: 'kazakh',
			localName: 'Қазақ тілі',
			englishName: 'Kazakh',
		},
		{
			locale: 'ko-KR',
			attributeName: 'korean',
			localName: '한국어',
			englishName: 'Korean',
		},
		{
			locale: 'lv-LV',
			attributeName: 'latvian',
			localName: 'Latviešu',
			englishName: 'Latvian',
		},
		{
			locale: 'lt-LT',
			attributeName: 'lithuanian',
			localName: 'Lietuvių',
			englishName: 'Lithuanian',
		},
		{
			locale: 'ms-MY',
			attributeName: 'malay',
			localName: 'Bahasa Melayu',
			englishName: 'Malay',
		},
		{
			locale: 'mt-MT',
			attributeName: 'maltese',
			localName: 'Malti',
			englishName: 'Maltese',
		},
		{
			locale: 'ar-MA',
			attributeName: 'morrocoLanguage',
			localName: 'اللهجة المغربية',
			englishName: 'Moroccan Arabic',
		},
		{
			locale: 'ne-NP',
			attributeName: 'nepali',
			localName: 'नेपाली',
			englishName: 'Nepali',
		},

		{
			locale: 'fa-IR',
			attributeName: 'persian',
			localName: 'فارسی',
			englishName: 'Persian',
		},
		{
			locale: 'pl-PL',
			attributeName: 'polish',
			localName: 'Polski',
			englishName: 'Polish',
		},
		{
			locale: 'pt-PT',
			attributeName: 'portuguese',
			localName: 'Português',
			englishName: 'Portuguese',
		},
		{
			locale: 'pt-BR',
			attributeName: 'brazil',
			localName: 'Português (Brasil)',
			englishName: 'Brazilian Portuguese',
		},
		{
			locale: 'pa-IN',
			attributeName: 'punjabi',
			localName: 'ਪੰਜਾਬੀ',
			englishName: 'Punjabi',
		},
		{
			locale: 'ro-RO',
			attributeName: 'romanian',
			localName: 'Română',
			englishName: 'Romanian',
		},
		{
			locale: 'sr-RS',
			attributeName: 'serbian',
			localName: 'Српски',
			englishName: 'Serbian',
		},
		{
			locale: 'sk-SK',
			attributeName: 'slovak',
			localName: 'Slovenčina',
			englishName: 'Slovak',
		},
		{
			locale: 'sl-SI',
			attributeName: 'slovenian',
			localName: 'Slovenščina',
			englishName: 'Slovenian',
		},
		{
			locale: 'tl-PH',
			attributeName: 'tagalog',
			localName: 'Tagalog',
			englishName: 'Tagalog',
		},
		{
			locale: 'ta-IN',
			attributeName: 'tamil',
			localName: 'தமிழ்',
			englishName: 'Tamil',
		},
		{
			locale: 'th-TH',
			attributeName: 'thai',
			localName: 'ไทย',
			englishName: 'Thai',
		},
		{
			locale: 'uk-UA',
			attributeName: 'ukranian',
			localName: 'Українська',
			englishName: 'Ukranian',
		},
		{
			locale: 'ur-PK',
			attributeName: 'urdu',
			localName: 'اردو',
			englishName: 'Urdu',
		},
		{
			locale: 'uz-UZ',
			attributeName: 'uzbek',
			localName: 'Oʻzbekcha',
			englishName: 'Uzbek',
		},
		{
			locale: 'vi-VN',
			attributeName: 'vietnamese',
			localName: 'Tiếng Việt',
			englishName: 'Vietnamese',
		},
		{
			locale: 'yi-DE',
			attributeName: 'yiddish',
			localName: 'ייִדיש',
			englishName: 'Yiddish',
		}*/,
	];
	const handleChangeLanguage = newLanguage => {
		const newLang = newLanguage.split('-')[0];
		localStorage.setItem('pifa-language', newLang);
		localStorage.setItem('i18nextLng', newLang);

		i18next
			.changeLanguage(newLang, (err, t) => {
				if (err) return console.log('language changing failed.', err);
				window.location.reload();
			})
			.then(r => console.log(r));
	};
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-evenly',
				gap: '10px',
				alignItems: 'center',
				alignContent: 'center',
			}}
		>
			<div
				style={{
					color: 'black',
					cursor: 'pointer',
					textTransform: 'none',
					display: 'flex',
					gap: '1rem',
					alignContent: 'center',
					alignItems: 'center',
				}}
				onClick={event => {
					setAnchorElSupport(event.currentTarget);
				}}
			>
				<Tooltip title="Change language" placement="top">
					<IconButton color="primary" onClick={event => setAnchorElSupport(event.currentTarget)}>
						<Language sx={{color: color}} />
					</IconButton>
				</Tooltip>
			</div>

			<Menu
				id="supportselector"
				anchorEl={anchorElSupport}
				disableScrollLock={true}
				sx={{margin: 0, width: {xs: '200px', sm: '200px', md: '300px', lg: '500px', xl: '500px'}}}
				PaperProps={{
					elevation: 2,
				}}
				transformOrigin={{horizontal: 'center', vertical: 'top'}}
				anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
				open={Boolean(anchorElSupport)}
				onClose={() => setAnchorElSupport(null)}
			>
				<MenuList sx={{paddingBottom: 0}}>
					{languages.map((m, index) => (
						<MenuItem key={index} onClick={() => handleChangeLanguage(m.locale)}>
							<Tooltip title={m.englishName}>
								<Typography variant="inherit">{m.localName}</Typography>
							</Tooltip>
						</MenuItem>
					))}
				</MenuList>
			</Menu>
		</div>
	);
};
