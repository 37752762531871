import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import {
  ArrowUpward,
  Instagram,
  MailOutline,
  Place,
  WhatsApp,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";

const logoStyle = {
  width: "240px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://pifa.se/">Pifa AB&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery("(max-width:700px)");
  const theme = useTheme();
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <Box sx={{ ml: "-15px" }}>
              <img
                src={
                  theme.palette.mode === "light"
                    ? "https://storage.googleapis.com/pifa.se/partihandel.se_row.png"
                    : "https://storage.googleapis.com/pifa.se/partihandel.se_row_inverted.png"
                }
                style={logoStyle}
                alt="partihandel.se"
              />
            </Box>
            {/*<Typography variant="body2" fontWeight={600} gutterBottom>
							{t('start.contact.title')}
						</Typography>
							<Typography variant="body2" color="text.secondary" mb={2}>
						Subscribe to our newsletter for weekly updates and promotions.
					</Typography>
					<Stack direction="row" spacing={1} useFlexGap>
						<TextField
							id="outlined-basic"
							hiddenLabel
							size="small"
							variant="outlined"
							fullWidth
							aria-label="Enter your email address"
							placeholder="Your email address"
							inputProps={{
								autocomplete: 'off',
								ariaLabel: 'Enter your email address',
							}}
						/>
						<Button variant="contained" color="primary" sx={{flexShrink: 0}}>
							Subscribe
						</Button>
					</Stack>*/}
          </Box>
        </Box>
        {/*<Box
					sx={{
						display: {xs: 'none', sm: 'flex'},
						flexDirection: 'column',
						gap: 1,
					}}
				>
					<Typography variant="body2" fontWeight={600}>
						Product
					</Typography>
					<Link color="text.secondary" href="#">
						Features
					</Link>
					<Link color="text.secondary" href="#">
						Testimonials
					</Link>
					<Link color="text.secondary" href="#">
						Highlights
					</Link>
					<Link color="text.secondary" href="#">
						Pricing
					</Link>
					<Link color="text.secondary" href="#">
						FAQs
					</Link>
				</Box>
					<Box
					sx={{
					display: {xs: 'none', sm: 'flex'},
					flexDirection: 'column',
					gap: 1,
				}}
			>
				<Typography variant="body2" fontWeight={600}>
					Company
				</Typography>
				<Link color="text.secondary" href="#">
					About us
				</Link>
				<Link color="text.secondary" href="#">
					Careers
				</Link>
				<Link color="text.secondary" href="#">
					Press
				</Link>
			</Box>
			<Box
				sx={{
					display: {xs: 'none', sm: 'flex'},
					flexDirection: 'column',
					gap: 1,
				}}
			>
				<Typography variant="body2" fontWeight={600}>
					Legal
				</Typography>
				<Link color="text.secondary" href="#">
					Terms
				</Link>
				<Link color="text.secondary" href="#">
					Privacy
				</Link>
				<Link color="text.secondary" href="#">
					Contact
				</Link>
			</Box>*/}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <div
            style={{
              color: "gray",
              textAlign: "left",
              paddingBottom: "1rem",
            }}
          >
            <Typography
              variant="h6"
              sx={(theme) => ({
                color: theme.palette.mode === "light" ? "black" : "#ffffff",
                margin: "auto 0",
                paddingBottom: "10px",
                fontWeight: 600,
              })}
            >
              Pifa AB
            </Typography>

            <Typography variant="body2">{t("footer.cid")}</Typography>
            <Typography variant="body2">
              {t("footer.ceo")}:{" "}
              <a
                style={{
                  textDecoration: "none",
                }}
                href="mailto:jeremias@pifa.se"
              >
                Jeremias Hillerberg
              </a>
            </Typography>
            <Typography variant="body2">Håkan Lundbergs gata 28</Typography>
            <Typography variant="body2">252 33 Helsingborg</Typography>
          </div>
          <RouterLink to="/gdpr">{t("footer.integritypolicy")}</RouterLink>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <RouterLink to="/cookies">{t("footer.cookiepolicy")}</RouterLink>
          <Copyright />
          <Grid
            item
            xs={12}
            sx={{
              textAlign: { xs: "center", md: "left", xl: "left" },
              padding: "2rem 0 0 0",
            }}
          >
            <Tooltip title="Whatsapp" placement="top-start">
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { xs: "left", md: "left", xl: "left" },
                }}
                className="hover-link"
              >
                <WhatsApp fontSize="small" style={{ marginRight: "10px" }} />
                <Typography variant="body2">
                  <a href="https://wa.me/+46737133797">Whatsapp</a>
                </Typography>
              </Grid>
            </Tooltip>
          </Grid>
          <Grid item xs={12} style={{ padding: "1rem 0 0 0" }}>
            <Tooltip title={t("footer.sendemail")} placement="bottom">
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { xs: "left", md: "left", xl: "left" },
                }}
                className="hover-link"
              >
                <MailOutline fontSize="small" style={{ marginRight: "10px" }} />
                <Typography variant="body2">
                  <a href="mailto:jeremias@pifa.se">jeremias[a]pifa.se</a>
                </Typography>
              </Grid>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "left", md: "left", xl: "left" },
              padding: "1rem 0 0 0",
            }}
          >
            <Tooltip
              title={t("footer.directionstooltip")}
              placement="bottom-start"
            >
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "left", xl: "left" },
                }}
                className="hover-link"
              >
                <Place fontSize="small" style={{ marginRight: "10px" }} />
                <Typography variant="body2">
                  <a href="https://goo.gl/maps/3yhURrWeosGt8xp28">
                    {t("footer.directions")}
                  </a>
                </Typography>
              </Grid>
            </Tooltip>
          </Grid>
        </div>
        <Stack
          direction={isMobileScreen ? "column" : "row"}
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            href="https://tiktok.com/@partihandel"
            color="inherit"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <Tooltip title="tiktok.com/@partihandel">
              <svg
                fill={theme.palette.mode === "light" ? "black" : "#ffffff"}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="40px"
                height="40px"
              >
                <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
              </svg>
            </Tooltip>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://instagram.com/partihandel.se"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <Tooltip title="instagram.com/partihandel.se">
              <Instagram />
            </Tooltip>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://github.com/pifa-group"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <Tooltip title="github.com/pifa-group">
              <FacebookIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/partihandel"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <Tooltip title="twitter.com/partihandel">
              <TwitterIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/partihanteraren/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <Tooltip title="linkedin.com/company/partihanteraren">
              <LinkedInIcon />
            </Tooltip>
          </IconButton>
        </Stack>
      </Box>
      <Box>
        <Button
          variant="contained"
          size="large"
          sx={{
            position: "relative",
            bottom: "10",
            background: theme.palette.mode === "light" ? "black" : "white",
            border:
              theme.palette.mode === "light"
                ? "solid white 1px"
                : "solid black 1px",
            color: theme.palette.mode === "light" ? "white" : "black",
            "&:hover": {
              backgroundColor:
                theme.palette.mode === "light" ? "white" : "black",
              color: theme.palette.mode === "light" ? "black" : "white",
              border:
                theme.palette.mode === "light"
                  ? "solid black 1px"
                  : "solid white 1px",
            },
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          startIcon={<ArrowUpward />}
        >
          {t("footer.tothetop")}
        </Button>
      </Box>
    </Container>
  );
}
