import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {
	translationAR,
	translationDA,
	translationDE,
	translationEN,
	translationES,
	translationFI,
	translationFR,
	translationHU,
	translationIT,
	translationNL,
	translationNO,
	translationRU,
	translationSV,
	translationTR,
	translationZH,
} from './index';
import messages_ar from './ar.json';
import messages_da from './da.json';
import messages_de from './de.json';
import messages_en from './en.json';
import messages_es from './es.json';
import messages_fi from './fi.json';
import messages_fr from './fr.json';
import messages_hu from './hu.json';
import messages_it from './it.json';
import messages_nb from './nb.json';
import messages_nl from './nl.json';
import messages_ru from './ru.json';
import messages_sv from './sv.json';
import messages_tr from './tr.json';
import messages_zh from './zh.json';

export const messages = {
	ar: messages_ar,
	da: messages_da,
	de: messages_de,
	en: messages_en,
	es: messages_es,
	fi: messages_fi,
	fr: messages_fr,
	hu: messages_hu,
	it: messages_it,
	nl: messages_nl,
	nb: messages_nb,
	ru: messages_ru,
	sv: messages_sv,
	tr: messages_tr,
	zh: messages_zh,
};
const resources = {
	en: {translation: translationEN},
	ar: {translation: translationAR},
	da: {translation: translationDA},
	de: {translation: translationDE},
	es: {translation: translationES},
	fi: {translation: translationFI},
	fr: {translation: translationFR},
	hu: {translation: translationHU},
	it: {translation: translationIT},
	no: {translation: translationNO},
	nl: {translation: translationNL},
	ru: {translation: translationRU},
	sv: {translation: translationSV},
	tr: {translation: translationTR},
	zh: {translation: translationZH},
};
export const DEFAULT_LANGUAGE = 'Swedish';
export const DEFAULT_LOCALE = 'sv-SE';
export const LOCALE_LOCALSTORAGE_KEY = 'pifa-language';
const locale = navigator.language.split(/[-_]/)[0];
i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'sv',
		keySeparator: '.',
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
