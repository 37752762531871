import React, { useEffect } from "react";
import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CookiePolicyPage() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t("cookiepolicypage.title");
  }, []);
  return (
    <Grid
      container
      sx={{
        margin: "0 auto",
        maxWidth: "1300px",
        minHeight: "50vh",
        padding: {
          xs: "7rem 1rem",
          sm: "8rem 1rem",
          md: "8rem 1rem",
          xl: "8rem 1rem",
        },
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        style={{ margin: "0 auto", maxWidth: "1300px" }}
      >
        <h1 style={{ fontWeight: "normal", margin: 0, color: "#4cae52" }}>
          {t("cookiepolicypage.title")}
        </h1>

        <Divider
          style={{ width: "100%", color: "gray", margin: "1rem auto 0" }}
        />

        <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {t("cookiepolicypage.section1title")}
        </p>
        <p>{t("cookiepolicypage.section1text1")}</p>
        <p>{t("cookiepolicypage.section1text2")}</p>
        <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {t("cookiepolicypage.section2title")}
        </p>
        <p>{t("cookiepolicypage.section2text1")}</p>
        <p style={{ fontWeight: "bold" }}>
          {t("cookiepolicypage.section2text3")}
        </p>
        <p>{t("cookiepolicypage.section2text4")}</p>
        <p>
          {t("cookiepolicypage.section2text5")}{" "}
          <a
            style={{ fontWeight: "bold", textDecoration: "underline" }}
            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
          >
            {t("cookiepolicypage.section2text6")}
          </a>
        </p>

        <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {t("cookiepolicypage.section3title")}
        </p>
        <p>{t("cookiepolicypage.section3text1")}</p>
        <p>{t("cookiepolicypage.section3text2")}</p>

        <p style={{ fontSize: "1.4rem", fontWeight: "bold" }}>
          {t("cookiepolicypage.section4title")}
        </p>
        <p>
          <strong>{t("cookiepolicypage.section4subtitle1")}</strong>
        </p>
        <Table
          style={{
            maxWidth: "500px",
            border: "solid black 1px",
            margin: "2rem 0",
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("cookiepolicypage.cookie")}</TableCell>
              <TableCell>{t("cookiepolicypage.purpose")}</TableCell>
              <TableCell>{t("cookiepolicypage.storagetime")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>_ga</TableCell>
              <TableCell>{t("cookiepolicypage.purpose1")}</TableCell>
              <TableCell>{t("cookiepolicypage.twoyears")}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>_ga_*</TableCell>
              <TableCell>{t("cookiepolicypage.purpose2")}</TableCell>
              <TableCell>{t("cookiepolicypage.twoyears")}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
