import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import { useTranslation } from "react-i18next";
import {
  AddShoppingCart,
  LocalShipping,
  MonetizationOn,
  Widgets,
} from "@mui/icons-material";

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const { t } = useTranslation();

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const items = [
    {
      icon: <MonetizationOn />,
      title: t("landingpage.section1title"),
      description: t("landingpage.section1text"),
      imageLight:
        'url("https://storage.googleapis.com/pifa.se/public/vintage.jpeg',
      imageDark: `url("https://storage.googleapis.com/pifa.se/public/vintage.jpeg")`,
      url: "https://storage.googleapis.com/pifa.se/public/vintage.jpeg",
    },

    {
      icon: <AddShoppingCart />,
      title: t("landingpage.section2title"),
      description: t("landingpage.section2text"),
      imageLight:
        'url("https://storage.googleapis.com/pifa.se/public/margoz.jpeg")',
      imageDark: `url("https://storage.googleapis.com/pifa.se/public/margoz.jpeg")`,
      url: "https://storage.googleapis.com/pifa.se/public/margoz.jpeg",
    },
    {
      icon: <Widgets />,
      title: t("landingpage.section3title"),
      description: t("landingpage.section3text"),
      imageLight:
        'url("https://storage.googleapis.com/pifa.se/public/ekebacken.jpeg")',
      imageDark: `url("https://storage.googleapis.com/pifa.se/public/ekebacken.jpeg")`,
      url: "https://storage.googleapis.com/pifa.se/public/ekebacken.jpeg",
    },
    {
      icon: <LocalShipping />,
      title: t("landingpage.section4title"),
      description: t("landingpage.section4text"),
      imageLight:
        'url("https://storage.googleapis.com/pifa.se/public/ruchey.jpeg")',
      imageDark: `url("https://storage.googleapis.com/pifa.se/public/ruchey.jpeg")`,
      url: "https://storage.googleapis.com/pifa.se/public/ruchey.jpeg",
    },
    {
      icon: <DevicesRoundedIcon />,
      title: t("landingpage.section5title"),
      description: t("landingpage.section5text"),
      imageLight:
        'url("https://storage.googleapis.com/pifa.se/public/kmais.jpeg")',
      imageDark: `url("https://storage.googleapis.com/pifa.se/public/kmais.jpeg")`,
      url: "https://storage.googleapis.com/pifa.se/public/kmais.jpeg",
    },
  ];
  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography
              component="body1"
              color="text.secondary"
              sx={{ fontSize: "1.4rem" }}
            >
              {t("landingpage.featurestitle")}
            </Typography>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ fontSize: "1.5rem", mb: { xs: 2, sm: 4 } }}
            >
              {t("landingpage.featurestext")}
            </Typography>
          </div>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "none", md: "auto" } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "primary.light" : "";
                    }
                    return selectedItemIndex === index ? "primary.light" : "";
                  },
                  background: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index ? "none" : "";
                    }
                    return selectedItemIndex === index ? "none" : "";
                  },
                  backgroundColor:
                    selectedItemIndex === index ? "primary.main" : "",
                  "& .MuiChip-label": {
                    color: selectedItemIndex === index ? "#fff" : "",
                  },
                }}
              />
            ))}
          </Grid>

          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: "100%", display: "flex" }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedItemIndex === index ? "action.selected" : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === "light") {
                      return selectedItemIndex === index
                        ? "primary.light"
                        : "grey.200";
                    }
                    return selectedItemIndex === index
                      ? "primary.dark"
                      : "grey.800";
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === "light") {
                          return selectedItemIndex === index
                            ? "primary.main"
                            : "grey.300";
                        }
                        return selectedItemIndex === index
                          ? "primary.main"
                          : "grey.700";
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <div>
                    <Typography
                      color="text.primary"
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                  </div>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", sm: "flex" },
            width: "100%",
            height: "700px",
            textAlign: "center",
            padding: 0,
            margin: 0,
          }}
        >
          <img src={items[selectedItemIndex].url} alt="fresh" />
        </Grid>
      </Grid>
    </Container>
  );
}
