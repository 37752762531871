import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { customerReferences } from "./customerReferences";
import { Tooltip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function LogoCollection() {
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery("(max-width:700px)");

  return (
    <Box
      id="logoCollection"
      sx={{
        background: "white",
        padding: { xs: "1rem 10px", md: "3rem 1rem" },
        zIndex: 500,
        opacity: 1,
      }}
    >
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {customerReferences.length > 0 &&
              customerReferences.map((c) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  xl={2}
                  key={c.key}
                  sx={{
                    padding: { xs: "3rem auto", md: "1rem" },
                    textAlign: "center",
                  }}
                >
                  <Tooltip title={c.companyName}>
                    <a href={c.websiteUrl}>
                      <img
                        src={c.logoUrl}
                        alt={c.companyName}
                        width={isMobileScreen ? "300" : "400"}
                      />
                    </a>
                  </Tooltip>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
