import * as React from "react";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import apelsin from "../../../assets/apelsin.webp";

export default function Hero() {
  const { t } = useTranslation();
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        minHeight: { xs: "70vh", md: "80vh" },
        backgroundImage: `url(${apelsin})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "4rem",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            backgroundColor: "rgba(42,63,63,0.82)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "2rem",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BFCCD9", 0.5)
                : alpha("#445f64", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#445f64", 0.2)}`
                : `0 0 24px 12px ${alpha("rgba(26,73,73,0.99)", 0.2)}`,
            p: { xs: 1, md: 2, xl: 3 }, // spacing around text
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // horizontally
            justifyContent: "center", // vertically
            textAlign: "center",
          })}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{
              width: { xs: "100%", sm: "90%", md: "90%" },
              minHeight: { xs: "0", md: "20vh" },
            }}
          >
            <Typography
              component="h1"
              variant="h1"
              sx={(theme) => ({
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignSelf: "center",
                textAlign: "center",
                mt: { xs: 4, sm: 8 },
                color: "white",
                fontSize: { xs: "2.2rem", md: "3rem" },
              })}
            >
              {t("startpage.herotitle")}.
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              color="text.primary"
              sx={{ fontSize: { xs: "1.6rem", md: "1.8rem" }, color: "white" }}
            >
              {t("startpage.herosubtitle")}
            </Typography>
            {/*<Stack
						direction={{xs: 'column', sm: 'row'}}
						alignSelf="center"
						spacing={1}
						useFlexGap
						sx={{pt: 2, width: {xs: '100%', sm: 'auto'}}}
					>
						<TextField
							id="outlined-basic"
							hiddenLabel
							size="small"
							variant="outlined"
							aria-label={t('start.contact.email')}
							placeholder={t('start.contact.email')}
							inputProps={{
								autocomplete: 'off',
								ariaLabel: t('start.contact.email'),
							}}
						/>
						<Button variant="contained" color="primary" startIcon={<ShoppingCartCheckout />}>
							{t('start.hero.waitlistbutton')}
						</Button>
					</Stack>
						<Typography variant="caption" textAlign="center" sx={{opacity: 0.8}}>
					{t('start.hero.waitlistformhelpertext')}
				</Typography>*/}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
