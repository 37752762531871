import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { t } = useTranslation();

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.secondary"
        sx={(theme) => ({
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
          color: theme.palette.mode === "light" ? "black" : "white",
        })}
      >
        {t("landingpage.topbar.faq")}
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              {t("landingpage.faq1")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              {t("landingpage.faq1text")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              {t("landingpage.faq2")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              {t("landingpage.faq2text")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              {t("landingpage.faq3")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              {t("landingpage.faq3text")}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              {t("landingpage.faq4")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              {t("landingpage.faq4text")}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
      {/*<Stack
				direction={{xs: 'column', sm: 'row'}}
				alignSelf="center"
				spacing={1}
				useFlexGap
				sx={{pt: 2, width: {xs: '100%', sm: 'auto'}}}
			>
				<TextField
					id="outlined-basic"
					hiddenLabel
					size="small"
					variant="outlined"
					aria-label={t('start.contact.email')}
					placeholder={t('start.contact.email')}
					inputProps={{
						autocomplete: 'off',
						ariaLabel: t('start.contact.email'),
					}}
				/>
				<Button variant="contained" color="primary" startIcon={<ShoppingCartCheckout />}>
					{t('start.hero.waitlistbutton')}
				</Button>
			</Stack>
				<Typography variant="caption" textAlign="center" sx={{opacity: 0.8}}>
			{t('start.hero.waitlistformhelpertext')}
		</Typography>
*/}
    </Container>
  );
}
